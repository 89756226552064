export default class TextUtils {
    static removeCharacters(value: string) {
        const newValue = value.replace(/[^a-zA-Z0-9]/g, '');
        return newValue;
    }

    static convertHouseStatus(value: string) {
        switch (value) {
            case 'ENABLED':
                return 'Ativo';
            case 'DISABLED':
                return 'Inativo';
            default:
                return '';
        }
    }

    static convertHouseStatusColor(value: string) {
        switch (value) {
            case 'ENABLED':
                return 'green.500';
            case 'DISABLED':
                return 'red.500';
            default:
                return 'yellow.500';
        }
    }
}
