import React from 'react';
import { Routes as RoutesDOM, Route } from 'react-router-dom';

import LoginPage from '../../modules/auth/pages/LoginPage';
import HouseEditPage from '../../modules/houses/pages/EditPage';
import HousesRegisterPage from '../../modules/houses/pages/RegisterPage';
import PlanEditPage from '../../modules/plans/pages/EditPage';
import PlansListPage from '../../modules/plans/pages/ListPage';
import PlansRegisterPage from '../../modules/plans/pages/RegisterPage';
import LayoutPanel from '../components/Layout/LayoutPanel';
import Dashboard from '../pages/Dashboard';

const Routes: React.FC = () => (
    <RoutesDOM>
        <Route path="/" element={<LayoutPanel component={<LoginPage />} />} />
        <Route
            path="/dashboard"
            element={<LayoutPanel component={<Dashboard />} />}
        />

        <Route
            path="/login"
            element={<LayoutPanel component={<LoginPage />} />}
        />

        <Route
            path="/houses/new"
            element={<LayoutPanel component={<HousesRegisterPage />} />}
        />

        <Route
            path="/houses/:id/edit"
            element={<LayoutPanel component={<HouseEditPage />} />}
        />

        <Route
            path="/plans"
            element={<LayoutPanel component={<PlansListPage />} />}
        />

        <Route
            path="/plans/new"
            element={<LayoutPanel component={<PlansRegisterPage />} />}
        />

        <Route
            path="/plans/:id/edit"
            element={<LayoutPanel component={<PlanEditPage />} />}
        />
    </RoutesDOM>
);

export default Routes;
