import React, { useCallback, useEffect, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Divider,
    Flex,
    Icon,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Form from '../../../../shared/components/Form';
import ModalDeleteConfirmation from '../../../../shared/components/ModalDeleteConfirmation';
import { HTTP_RESPONSE } from '../../../../shared/constants';

const PlansListPage: React.FC = () => {
    const navigate = useNavigate();

    const toast = useToast();

    const [plans, setPlans] = useState<any[]>([]);

    const FIELDS = [
        {
            name: 'Plano',
            minWidth: '256px'
        },
        {
            name: 'Valor',
            minWidth: '256px'
        },
        {
            name: ' Nº Próprio Whats',
            minWidth: '160px'
        },
        {
            name: 'Ação',
            maxWidth: '120px'
        }
    ];

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selectedItem, setSelectedItem] = useState('');

    const handleDelete = useCallback(async (id: string) => {
        setSelectedItem(id);
        onOpen();
    }, []);

    const deleteFunction = useCallback(async () => {
        const { status } = await apiBackend('').delete(
            `/plans/${selectedItem}`
        );

        if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
            toast({
                title: 'Plano Removido',
                description: '',
                status: 'success',
                duration: 4000,
                isClosable: true
            });

            setPlans(oldState => {
                const updatedList = oldState.filter(
                    item => item.id !== selectedItem
                );

                return [...updatedList];
            });
        }
    }, [selectedItem]);

    const loadData = useCallback(async () => {
        const { status, data } = await apiBackend('').get('/plans');

        if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
            setPlans(data);
        }
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Form>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/plans">Planos</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    p="16px"
                    justifyContent="space-between"
                    backgroundColor="white"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Flex width="100%" justifyContent="space-between">
                        <Button
                            title="+ Novo Plano"
                            backgroundColor="green.500"
                            color="white"
                            width="192px"
                            minWidth="192px"
                            borderRadius="4px"
                            py="8px"
                            fontSize="14px"
                            onClick={() => navigate('/plans/new')}
                        />
                    </Flex>

                    <Flex width="100%" flexDirection="column" mt="24px">
                        <Flex width="100%" justifyContent="space-between">
                            <Text fontWeight="600" color="green.500">
                                Planos
                            </Text>
                        </Flex>

                        <Divider my="16px" />

                        <Flex
                            width="100%"
                            flexDirection="column"
                            overflow="auto"
                        >
                            <Flex width="100%">
                                {FIELDS.map(field => (
                                    <Flex
                                        key={field.name}
                                        width="100%"
                                        minWidth={field.minWidth}
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        <Text>{field.name}</Text>
                                    </Flex>
                                ))}
                            </Flex>
                            <Flex
                                width="100%"
                                fontSize="12px"
                                fontWeight="500"
                                flexDirection="column"
                            >
                                <Divider my="4px" />
                                {plans.map(plan => (
                                    <Flex
                                        width="100%"
                                        py="8px"
                                        flexDirection="column"
                                    >
                                        <Flex key={plan.id} width="100%">
                                            <Flex
                                                width="100%"
                                                minWidth={FIELDS[0].minWidth}
                                            >
                                                <Text>{plan.name}</Text>
                                            </Flex>

                                            <Flex
                                                width="100%"
                                                minWidth={FIELDS[1].minWidth}
                                            >
                                                <Text>{plan.value}</Text>
                                            </Flex>

                                            <Flex
                                                width="100%"
                                                minWidth={FIELDS[2].minWidth}
                                            >
                                                <Text>
                                                    {plan.custom_number === true
                                                        ? 'Sim'
                                                        : 'Não'}
                                                </Text>
                                            </Flex>

                                            <Flex
                                                width="100%"
                                                minWidth={FIELDS[3].minWidth}
                                            >
                                                <Flex
                                                    width="24px"
                                                    height="24px"
                                                    backgroundColor="transparent"
                                                    color="white"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    title="Editar"
                                                    cursor="pointer"
                                                    borderRadius="50%"
                                                    onClick={() =>
                                                        navigate(
                                                            `/plans/${plan.id}/edit`
                                                        )
                                                    }
                                                >
                                                    <Icon
                                                        as={FiEdit}
                                                        fontSize="18px"
                                                        color="green.500"
                                                    />
                                                </Flex>
                                                <Flex
                                                    width="24px"
                                                    height="24px"
                                                    backgroundColor="transparent"
                                                    color="white"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    title="Excluir"
                                                    cursor="pointer"
                                                    borderRadius="50%"
                                                    onClick={() =>
                                                        handleDelete(plan.id)
                                                    }
                                                >
                                                    <Icon
                                                        as={FiTrash2}
                                                        fontSize="18px"
                                                        color="red.500"
                                                    />
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        <Divider my="4px" />
                                    </Flex>
                                ))}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <ModalDeleteConfirmation
                    deleteFunction={deleteFunction}
                    isOpen={isOpen}
                    onClose={onClose}
                />
            </Flex>
        </Form>
    );
};

export default PlansListPage;
