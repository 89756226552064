import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiCheck, FiEdit, FiTrash2, FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
    Divider,
    Flex,
    Icon,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../apis';
import Button from '../components/Button';
import Card from '../components/Card';
import Form from '../components/Form';
import Input from '../components/Input';
import ModalDeleteConfirmation from '../components/ModalDeleteConfirmation';
import Select from '../components/Select';
import { HTTP_RESPONSE } from '../constants';
import TextUtils from '../utils/TextUtils';

const Dashboard: React.FC = () => {
    const [defaultHouses, setDefaultHouses] = useState<any>([]);
    const [houses, setHouses] = useState<any[]>([]);

    const navigate = useNavigate();
    const toast = useToast();
    const formRef = useRef<FormHandles>(null);

    const FIELDS = [
        {
            name: 'Nome da Casa',
            minWidth: '256px'
        },
        {
            name: 'Responsável',
            minWidth: '256px'
        },
        {
            name: 'API Whats',
            minWidth: '100px'
        },
        {
            name: 'Telefone',
            minWidth: '100px'
        },
        {
            name: 'Plano',
            minWidth: '120px'
        },
        {
            name: 'Status',
            minWidth: '120px'
        },
        {
            name: 'Ação',
            minWidth: '120px'
        }
    ];

    const handleSearch = useCallback(
        (value: string) => {
            if (value.length > 0) {
                const regex = new RegExp(value, 'i');
                const filtredHouses = defaultHouses.filter((people: any) =>
                    people.name.match(regex)
                );

                setHouses(filtredHouses);
            } else {
                setHouses(defaultHouses);
            }
        },
        [defaultHouses]
    );

    const handleLoad = useCallback(async () => {
        const { status, data } = await apiBackend('').get('/houses');

        if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
            setHouses(data);
            setDefaultHouses(data);
        }
    }, []);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selectedItem, setSelectedItem] = useState('');

    const handleDelete = useCallback(async (id: string) => {
        setSelectedItem(id);
        onOpen();
    }, []);

    const deleteFunction = useCallback(async () => {
        const { status } = await apiBackend('').delete(
            `/houses/${selectedItem}`
        );

        if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
            toast({
                title: 'Casa de Eventos Removida',
                description: '',
                status: 'success',
                duration: 4000,
                isClosable: true
            });

            setHouses(oldState => {
                const updatedList = oldState.filter(
                    item => item.id !== selectedItem
                );

                return [...updatedList];
            });
        }
    }, [selectedItem]);

    const handleChangeFilter = useCallback(() => {
        const value = formRef.current?.getFieldValue('status_filter');

        if (value !== '-1') {
            if (value === '1') {
                const updatedList = defaultHouses.filter(
                    item => item.status === 'ENABLED'
                );

                setHouses(updatedList);
            } else {
                const updatedList = defaultHouses.filter(
                    item => item.status === 'DISABLED'
                );

                setHouses(updatedList);
            }
        } else {
            setHouses(defaultHouses);
        }
    }, []);

    useEffect(() => {
        handleLoad();
    }, [handleLoad]);

    return (
        <Form ref={formRef}>
            <Flex width="100%" flexDirection="column">
                <Flex
                    width="100%"
                    flexWrap={['nowrap', 'nowrap', 'nowrap', 'wrap']}
                    overflow="auto"
                >
                    <Card
                        title="Casas Ativas"
                        value={
                            houses?.filter(house => house.status === 'ENABLED')
                                .length
                        }
                        color="green.500"
                    />
                    <Card
                        title="Casas Inativas"
                        value={
                            houses?.filter(house => house.status === 'DISABLED')
                                .length
                        }
                        color="red.500"
                    />
                </Flex>

                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    p="16px"
                >
                    <Button
                        title="+ Nova Casa"
                        backgroundColor="green.500"
                        color="white"
                        width="192px"
                        minWidth="192px"
                        borderRadius="4px"
                        py="8px"
                        fontSize="14px"
                        onClick={() => navigate('/houses/new')}
                        mb="24px"
                    />

                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        flexDirection={['column', 'column', 'row']}
                    >
                        <Text fontWeight="600" color="green.500">
                            Casa de Eventos
                        </Text>

                        <Input
                            name="search"
                            placeholder="Pesquisar por Nome"
                            maxWidth="300px"
                            mb="0px"
                            onChange={e => handleSearch(e.currentTarget.value)}
                            my={['16px', '16px', '0px']}
                        />

                        <Flex alignItems="center">
                            <Text fontSize="14px" mr="16px" fontWeight="500">
                                Filtrar por:
                            </Text>
                            <Select
                                width="88px"
                                name="status_filter"
                                size="sm"
                                mb="0px"
                                onChange={() => handleChangeFilter()}
                            >
                                <option value="-1">Todos</option>
                                <option value="1">Ativo</option>
                                <option value="0">Inativo</option>
                            </Select>
                        </Flex>
                    </Flex>

                    <Divider mt="16px" mb="8px" />

                    <Flex width="100%" flexDirection="column" overflow="auto">
                        <Flex width="100%">
                            {FIELDS.map(field => (
                                <Flex
                                    key={field.name}
                                    width="100%"
                                    minWidth={field.minWidth}
                                    fontSize="14px"
                                    fontWeight="600"
                                >
                                    <Text>{field.name}</Text>
                                </Flex>
                            ))}
                        </Flex>
                        <Flex
                            width="100%"
                            flexDirection="column"
                            fontSize="14px"
                        >
                            <Divider my="4px" />
                            {houses.map(house => (
                                <Flex
                                    key={house.id}
                                    width="100%"
                                    fontSize="12px"
                                    fontWeight="500"
                                    flexDirection="column"
                                >
                                    <Flex width="100%" py="8px">
                                        <Flex
                                            width="100%"
                                            minWidth={FIELDS[0].minWidth}
                                        >
                                            <Text>{house.name}</Text>
                                        </Flex>

                                        <Flex
                                            width="100%"
                                            minWidth={FIELDS[1].minWidth}
                                        >
                                            <Text>{house.contractor}</Text>
                                        </Flex>

                                        <Flex
                                            width="100%"
                                            minWidth={FIELDS[2].minWidth}
                                        >
                                            {house.api_whatsapp_id &&
                                                house.api_whatsapp_secret && (
                                                    <Flex
                                                        width="24px"
                                                        height="24px"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        backgroundColor="green.500"
                                                        color="white"
                                                        borderRadius="4px"
                                                        ml="24px"
                                                    >
                                                        <Icon
                                                            as={FiCheck}
                                                            fontSize="20px"
                                                        />
                                                    </Flex>
                                                )}

                                            {(!house.api_whatsapp_id ||
                                                !house.api_whatsapp_secret) && (
                                                <Flex
                                                    width="24px"
                                                    height="24px"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    backgroundColor="red.500"
                                                    color="white"
                                                    borderRadius="4px"
                                                    ml="24px"
                                                >
                                                    <Icon
                                                        as={FiX}
                                                        fontSize="20px"
                                                    />
                                                </Flex>
                                            )}
                                        </Flex>

                                        <Flex
                                            width="100%"
                                            minWidth={FIELDS[3].minWidth}
                                        >
                                            <Text>{house.whatsapp}</Text>
                                        </Flex>

                                        <Flex
                                            width="100%"
                                            minWidth={FIELDS[4].minWidth}
                                        >
                                            <Text>{house?.plan?.name}</Text>
                                        </Flex>

                                        <Flex
                                            width="100%"
                                            minWidth={FIELDS[5].minWidth}
                                        >
                                            <Text
                                                color={TextUtils.convertHouseStatusColor(
                                                    house.status
                                                )}
                                                fontWeight="600"
                                            >
                                                {TextUtils.convertHouseStatus(
                                                    house.status
                                                )}
                                            </Text>
                                        </Flex>

                                        <Flex
                                            width="100%"
                                            minWidth={FIELDS[6].minWidth}
                                            alignItems="center"
                                        >
                                            <Flex
                                                width="24px"
                                                height="24px"
                                                backgroundColor="transparent"
                                                color="green.500"
                                                justifyContent="center"
                                                alignItems="center"
                                                title="Editar"
                                                cursor="pointer"
                                                borderRadius="50%"
                                                onClick={() =>
                                                    navigate(
                                                        `/houses/${house.id}/edit`
                                                    )
                                                }
                                            >
                                                <Icon
                                                    as={FiEdit}
                                                    fontSize="18px"
                                                    color="green.500"
                                                />
                                            </Flex>

                                            <Flex
                                                width="24px"
                                                height="24px"
                                                backgroundColor="transparent"
                                                color="green.500"
                                                justifyContent="center"
                                                alignItems="center"
                                                title="Excluir"
                                                cursor="pointer"
                                                borderRadius="50%"
                                                onClick={() =>
                                                    handleDelete(house.id)
                                                }
                                            >
                                                <Icon
                                                    as={FiTrash2}
                                                    fontSize="18px"
                                                    color="red.500"
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Divider my="4px" />
                                </Flex>
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
                <ModalDeleteConfirmation
                    deleteFunction={deleteFunction}
                    isOpen={isOpen}
                    onClose={onClose}
                />
            </Flex>
        </Form>
    );
};

export default Dashboard;
