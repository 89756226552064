import { format } from 'date-fns';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface ILayoutContextData {
    title: string;
    user: any;
    showMenu: boolean;
    currentUnity: any;
    setShowMenu(value: boolean): void;
    setTitle(title: string): void;
    setUser(user: any): void;
    setCurrentUnity(unity: any): void;
}

const LayoutContext = createContext<ILayoutContextData>(
    {} as ILayoutContextData
);

const LayoutDefault: React.FC<any> = ({ children }) => {
    const [title, setTitle] = useState('');
    const [user, setUser] = useState(null as any);
    const [showMenu, setShowMenu] = useState(false);
    const [currentUnity, setCurrentUnity] = useState(null as any);

    useEffect(() => {
        const userData = localStorage.getItem(
            `@recept:master:session:${format(new Date().getDate(), 'ddMMyyyy')}`
        );

        if (userData) {
            const { token, user } = JSON.parse(userData);

            setUser({
                token,
                user
            });
        }
    }, []);

    return (
        <LayoutContext.Provider
            value={{
                title,
                user,
                showMenu,
                currentUnity,
                setTitle,
                setUser,
                setShowMenu,
                setCurrentUnity
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};

export const useLayout = (): ILayoutContextData => {
    const context = useContext(LayoutContext);

    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }

    return context;
};

export default LayoutDefault;
