import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Divider,
    Flex,
    Radio,
    RadioGroup,
    Stack,
    Text,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import Select from '../../../../shared/components/Select';
import { HTTP_RESPONSE } from '../../../../shared/constants';

const PlansRegisterPage: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const toast = useToast();
    const navigate = useNavigate();

    const [questions, setQuestions] = useState(['0', '0', '0', '0', '0', '0']);

    const handleChangeQuestion = useCallback((index, value) => {
        setQuestions(oldState => {
            const updated = oldState;

            updated[index] = value;

            return [...updated];
        });
    }, []);

    const handleSubmit = useCallback(async () => {
        const data: any = formRef.current?.getData();

        const { status } = await apiBackend('').post('/plans', {
            ...data,
            greeting_message: questions[0] === '1',
            custom_message: questions[1] === '1',
            custom_question: questions[2] === '1',
            relatory_email: questions[3] === '1',
            custom_number: questions[4] === '1',
            marketing_module: questions[5] === '1'
        });

        if (status === HTTP_RESPONSE.STATUS.CREATED) {
            toast({
                title: 'Cadastro Realizado',
                description: '',
                status: 'success',
                duration: 4000,
                isClosable: true
            });

            navigate('/plans');
        }
    }, [formRef, questions]);

    return (
        <Form ref={formRef}>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href="/plans">Planos</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/plans/new">
                            Novo Plano
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    flexDirection="column"
                    color="gray.900"
                    backgroundColor="white"
                    p="32px"
                >
                    <Text fontWeight="500" fontSize="14px" mb="13px">
                        Novo Plano
                    </Text>

                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        flexDirection={['column', 'column', 'row']}
                    >
                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                        >
                            <Input name="name" label="Nome do Plano" />

                            <Select
                                name="num_receptionists"
                                label="Nº de recepcionistas"
                            >
                                <option value="-1">Ilimitado</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                            </Select>

                            <Select
                                name="num_free_searches"
                                label="Quantidade de Envio Pesquisa de satisfação por whatsap Grátis"
                            >
                                <option value="-1">Ilimitado</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="300">300</option>
                                <option value="500">500</option>
                            </Select>
                        </Flex>

                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                        >
                            <Select name="num_events" label="Eventos por mês">
                                <option value="-1">Ilimitado</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="250">250</option>
                                <option value="300">300</option>
                                <option value="500">500</option>
                            </Select>

                            <Select
                                name="num_questions"
                                label="Perguntas na pesquisa de satisfação"
                            >
                                <option value="-1">Ilimitado</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                            </Select>

                            <Select
                                name="num_guests"
                                label="Convidados por mês"
                            >
                                <option value="-1">Ilimitado</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="250">250</option>
                                <option value="300">300</option>
                                <option value="400">400</option>
                                <option value="500">500</option>
                                <option value="600">600</option>
                                <option value="700">700</option>
                                <option value="800">800</option>
                                <option value="900">900</option>
                                <option value="1000">1000</option>
                                <option value="1500">1500</option>
                                <option value="2000">2000</option>
                                <option value="3000">3000</option>
                            </Select>
                        </Flex>
                    </Flex>

                    <Flex width="100%" flexDirection="column">
                        <Flex
                            justifyContent="space-between"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            <Text>Mensagem de saudação no Whatsapp</Text>

                            <RadioGroup
                                onChange={value =>
                                    handleChangeQuestion(0, value)
                                }
                                defaultValue={questions[0]}
                                colorScheme="green"
                                size="sm"
                            >
                                <Stack direction={['column', 'column', 'row']}>
                                    <Radio value="1">Sim</Radio>
                                    <Radio value="0">Não</Radio>
                                </Stack>
                            </RadioGroup>
                        </Flex>
                        <Divider my="8px" />
                        <Flex
                            justifyContent="space-between"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            <Text>Personalização da mensagem de saudação</Text>

                            <RadioGroup
                                onChange={value =>
                                    handleChangeQuestion(1, value)
                                }
                                defaultValue={questions[1]}
                                colorScheme="green"
                                size="sm"
                            >
                                <Stack direction={['column', 'column', 'row']}>
                                    <Radio value="1">Sim</Radio>
                                    <Radio value="0">Não</Radio>
                                </Stack>
                            </RadioGroup>
                        </Flex>
                        <Divider my="8px" />
                        <Flex
                            justifyContent="space-between"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            <Text>Personalização da pergunta na pesquisa</Text>

                            <RadioGroup
                                onChange={value =>
                                    handleChangeQuestion(2, value)
                                }
                                defaultValue={questions[2]}
                                colorScheme="green"
                                size="sm"
                            >
                                <Stack direction={['column', 'column', 'row']}>
                                    <Radio value="1">Sim</Radio>
                                    <Radio value="0">Não</Radio>
                                </Stack>
                            </RadioGroup>
                        </Flex>
                        <Divider my="8px" />
                        <Flex
                            justifyContent="space-between"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            <Text>Envio de relatorios por email/ whatsapp</Text>

                            <RadioGroup
                                onChange={value =>
                                    handleChangeQuestion(3, value)
                                }
                                defaultValue={questions[3]}
                                colorScheme="green"
                                size="sm"
                            >
                                <Stack direction={['column', 'column', 'row']}>
                                    <Radio value="1">Sim</Radio>
                                    <Radio value="0">Não</Radio>
                                </Stack>
                            </RadioGroup>
                        </Flex>
                        <Divider my="8px" />
                        <Flex
                            justifyContent="space-between"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            <Text>
                                Numero próprio de whatsapp + atendimento
                            </Text>

                            <RadioGroup
                                onChange={value =>
                                    handleChangeQuestion(4, value)
                                }
                                defaultValue={questions[4]}
                                colorScheme="green"
                                size="sm"
                            >
                                <Stack direction={['column', 'column', 'row']}>
                                    <Radio value="1">Sim</Radio>
                                    <Radio value="0">Não</Radio>
                                </Stack>
                            </RadioGroup>
                        </Flex>
                        <Divider my="8px" />
                        <Flex
                            justifyContent="space-between"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            <Text>
                                Modulo Marketing / atendimento (Envio de
                                campanhas por whatsapp)
                            </Text>

                            <RadioGroup
                                onChange={value =>
                                    handleChangeQuestion(5, value)
                                }
                                defaultValue={questions[5]}
                                colorScheme="green"
                                size="sm"
                            >
                                <Stack direction={['column', 'column', 'row']}>
                                    <Radio value="1">Sim</Radio>
                                    <Radio value="0">Não</Radio>
                                </Stack>
                            </RadioGroup>
                        </Flex>
                    </Flex>

                    <Flex width="100%" mt="24px">
                        <Input
                            width="200px"
                            name="value"
                            label="Valor Mensal"
                            type="number"
                        />
                    </Flex>

                    <Flex width="100%" justifyContent="center">
                        <Button
                            title="Cadastrar"
                            backgroundColor="green.500"
                            color="white"
                            width="160px"
                            borderRadius="4px"
                            py="8px"
                            onClick={() => handleSubmit()}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default PlansRegisterPage;
