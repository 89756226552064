import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Text,
    useToast
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import apiBackend from '../../../../shared/apis';
import Button from '../../../../shared/components/Button';
import Form from '../../../../shared/components/Form';
import Input from '../../../../shared/components/Input';
import Select from '../../../../shared/components/Select';
import UploadPhoto from '../../../../shared/components/UploadPhoto';
import { HTTP_RESPONSE } from '../../../../shared/constants';
import { useLayout } from '../../../../shared/hooks/layout';

const HousesRegisterPage: React.FC = () => {
    const formRef = useRef<FormHandles>(null as any);
    const { user } = useLayout();

    const toast = useToast();
    const navigate = useNavigate();
    const [plans, setPlans] = useState<any[]>([]);

    const [dataUri, setDataUri] = useState('');

    const handleSearchCEP = useCallback(async () => {
        const cepRaw = formRef.current.getFieldValue('zipcode');
        const cep = cepRaw.replace(/\D/g, '');

        const { data } = await axios.get(
            `https://viacep.com.br/ws/${cep}/json`
        );

        if (!data.erro) {
            formRef.current.getFieldRef('city').value = data.localidade;

            formRef.current.getFieldRef('street').value = data.logradouro;
            formRef.current.getFieldRef('number').value = '';
            formRef.current.getFieldRef('complement').value = '';
            formRef.current.getFieldRef('uf').value = data.uf;
        }
    }, [formRef]);

    const handleSubmit = useCallback(async () => {
        const formData = formRef.current?.getData();

        const { status, data } = await apiBackend(user?.token).post('/houses', {
            ...formData,
            api_whatsapp_secret: '',
            logo_url: dataUri
        });

        if (status === HTTP_RESPONSE.STATUS.CREATED) {
            toast({
                title: 'Cadastro Realizado',
                description: '',
                status: 'success',
                duration: 4000,
                isClosable: true
            });

            navigate('/dashboard');
        }

        if (status === HTTP_RESPONSE.STATUS.BAD_REQUEST) {
            toast({
                title: 'Atenção',
                description: data.message,
                status: 'warning',
                duration: 4000,
                isClosable: true
            });
        }
    }, [user, formRef, dataUri]);

    const loadData = useCallback(async () => {
        const { status, data } = await apiBackend('').get('/plans');

        if (status === HTTP_RESPONSE.STATUS.SUCCESS) {
            setPlans(data);
        }
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Form ref={formRef}>
            <Flex width="100%" flexDirection="column">
                <Breadcrumb
                    mb="16px"
                    fontWeight="500"
                    color="gray.900"
                    fontSize="14px"
                >
                    <BreadcrumbItem>
                        <BreadcrumbLink href="/dashboard">
                            Início
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink href="/houses">
                            Casas de Eventos
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem isCurrentPage>
                        <BreadcrumbLink href="/houses/new">
                            Nova Casa
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>

                <Flex
                    width="100%"
                    flexDirection="column"
                    color="gray.900"
                    backgroundColor="white"
                    p="32px"
                >
                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        flexDirection={['column', 'column', 'row']}
                        flexWrap="wrap"
                    >
                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                        >
                            <Input name="name" label="Empresa" isRequired />

                            <Input name="email" label="E-mail" isRequired />

                            <Input
                                name="contractor"
                                label="Nome Responsável"
                                isRequired
                            />
                        </Flex>

                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                        >
                            <Input
                                name="CNPJ"
                                label="CNPJ - Matriz"
                                mask="99.999.999/9999-99"
                                isRequired
                            />

                            <Input
                                name="whatsapp"
                                label="Whatsapp"
                                isRequired
                                mask="(99) 99999-9999"
                            />
                        </Flex>

                        <Flex width="100%">
                            <Text fontWeight="600" fontSize="14px">
                                Endereço
                            </Text>
                        </Flex>

                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                        >
                            <Input
                                name="zipcode"
                                label="CEP"
                                onChange={() => handleSearchCEP()}
                            />

                            <Input name="street" label="Rua" />
                        </Flex>

                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                        >
                            <Flex width="100%" justifyContent="space-between">
                                <Select
                                    name="uf"
                                    label="UF"
                                    width="48%"
                                    placeholder="Selecione Estado"
                                >
                                    <option value="AC">Acre</option>
                                    <option value="AL">Alagoas</option>
                                    <option value="AP">Amapá</option>
                                    <option value="AM">Amazonas</option>
                                    <option value="BA">Bahia</option>
                                    <option value="CE">Ceará</option>
                                    <option value="DF">Distrito Federal</option>
                                    <option value="ES">Espírito Santo</option>
                                    <option value="GO">Goiás</option>
                                    <option value="MA">Maranhão</option>
                                    <option value="MT">Mato Grosso</option>
                                    <option value="MS">
                                        Mato Grosso do Sul
                                    </option>
                                    <option value="MG">Minas Gerais</option>
                                    <option value="PA">Pará</option>
                                    <option value="PB">Paraíba</option>
                                    <option value="PR">Paraná</option>
                                    <option value="PE">Pernambuco</option>
                                    <option value="PI">Piauí</option>
                                    <option value="RJ">Rio de Janeiro</option>
                                    <option value="RN">
                                        Rio Grande do Norte
                                    </option>
                                    <option value="RS">
                                        Rio Grande do Sul
                                    </option>
                                    <option value="RO">Rondônia</option>
                                    <option value="RR">Roraima</option>
                                    <option value="SC">Santa Catarina</option>
                                    <option value="SP">São Paulo</option>
                                    <option value="SE">Sergipe</option>
                                    <option value="TO">Tocantins</option>
                                    <option value="EX">Estrangeiro</option>
                                </Select>

                                <Input name="city" label="Cidade" width="48%" />
                            </Flex>

                            <Flex width="100%" justifyContent="space-between">
                                <Input name="number" label="Nº" width="48%" />
                                <Input
                                    name="complement"
                                    label="Complemento"
                                    width="48%"
                                />
                            </Flex>
                        </Flex>

                        <Flex width="100%">
                            <Text fontWeight="600" fontSize="14px">
                                Configurações
                            </Text>
                        </Flex>

                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                        >
                            <Input name="login" label="Login" isRequired />

                            <Input
                                name="api_whatsapp_id"
                                label="Public ID (API GATEWAY)"
                            />

                            <Input
                                name="api_num_whats"
                                label="Nº do Whats personalizado"
                                mask="(99) 99999-9999"
                            />

                            <Select name="plan_id" label="Plano">
                                {plans.map(plan => (
                                    <option key={plan.id} value={plan.id}>
                                        {plan.name}
                                    </option>
                                ))}
                            </Select>
                        </Flex>

                        <Flex
                            width={['100%', '100%', '48%']}
                            flexDirection="column"
                        >
                            <Input
                                name="password"
                                label="Senha"
                                type="password"
                                isRequired
                            />

                            <Flex flexDirection="column">
                                <UploadPhoto setFunction={setDataUri} />
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex width="100%" justifyContent="center" mt="24px">
                        <Button
                            title="Cadastrar"
                            backgroundColor="green.500"
                            color="white"
                            width="160px"
                            borderRadius="4px"
                            py="8px"
                            onClick={() => handleSubmit()}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default HousesRegisterPage;
