import React, { useCallback } from 'react';
import { FiBox, FiCommand, FiLogOut, FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Flex, Box, Image, Icon, Text, Stack } from '@chakra-ui/react';

import logoWhite from '../../../assets/images/white-logo.png';
import { useLayout } from '../../hooks/layout';

const SideMenu: React.FC = () => {
    const { user, setUser, setShowMenu } = useLayout();

    const navigate = useNavigate();

    const MENU = [
        {
            title: 'Dashboard',
            icon: FiCommand,
            link: '/dashboard',
            submenus: []
        },
        {
            title: 'Planos',
            icon: FiBox,
            link: '/plans',
            submenus: []
        }
    ];

    const handleClickMenu = useCallback((item: any) => {
        navigate(item.link);
        setShowMenu(false);
    }, []);

    const handleLogout = useCallback(async () => {
        setShowMenu(false);
        localStorage.clear();
        setUser(null);
        navigate('/login');
    }, [user]);

    return (
        <Flex
            width="296px"
            height="100vh"
            flexDirection="column"
            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
            backgroundColor="white"
            position="fixed"
        >
            <Flex
                width="100%"
                justifyContent="center"
                height="80px"
                backgroundColor="orange.500"
                position="relative"
            >
                <Box>
                    <Image src={logoWhite} />
                </Box>

                <Flex
                    width="24px"
                    height="24px"
                    backgroundColor="orange.500"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    top="0"
                    right="0"
                    onClick={() => setShowMenu(false)}
                    display={['flex', 'flex', 'flex', 'none']}
                >
                    <Icon as={FiX} color="white" />
                </Flex>
            </Flex>

            <Stack pl="16px" mt="auto">
                {MENU.map(item => (
                    <Flex
                        key={item.title}
                        height="32px"
                        alignItems="center"
                        onClick={() => handleClickMenu(item)}
                        cursor="pointer"
                        pl="16px"
                    >
                        <Icon as={item.icon} color="gray.900" mr="8px" />
                        <Text color="gray.900" fontSize="14px">
                            {item.title}
                        </Text>
                    </Flex>
                ))}

                <Flex
                    height="32px"
                    alignItems="center"
                    onClick={() => handleLogout()}
                    cursor="pointer"
                    pl="16px"
                >
                    <Icon as={FiLogOut} color="gray.900" mr="8px" />
                    <Text color="gray.900" fontSize="14px">
                        Sair
                    </Text>
                </Flex>
            </Stack>

            <Flex
                width="100%"
                mt="auto"
                justifyContent="center"
                py="8px"
                cursor="pointer"
            ></Flex>
        </Flex>
    );
};

export default SideMenu;
